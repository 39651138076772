import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
  AppointmentType,
  CreateAppointmentRequest,
  CreateAvailabilitySlotRequest, EditAppointmentRequest,
  StoreLocation
} from '../crm-service/appointment';
import {LoggingService} from '../logging.service';
import {AuthService} from '../auth-service/auth.service';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {AppointmentBackendService, AppointmentSearchResponseResult, AvailableAgent, Timeslot} from './appointment.backend.service';
import {catchError, map} from 'rxjs/operators';
import {MicrosoftTimeZone, Timezones} from '../../utils/timezones';
import moment from 'moment-timezone';
import {StatusEnum} from '../../enums/appointment-status';

export interface IAppointmentService {
  filterAppointmentData(
    appointmentTypeId: number,
    callType: string,
    status: number,
    agents: string[],
    from: string,
    to: string,
    pagesize: number,
    pagenumber: number): Observable<AppointmentSearchResponseResult>;

  updateAppointmentStatus(appointmentGuid: string, status: number): Observable<boolean>;

  createAvailabilityBlock(request: CreateAvailabilitySlotRequest): Observable<boolean>;

  updateAvailabilityBlock(appointmentGuid: string, request: CreateAvailabilitySlotRequest): Observable<boolean>;

  deleteAvailabilityBlock(appointmentGuid: string): Observable<boolean>;

  createAppointment(appointment: CreateAppointmentRequest): Observable<boolean>;

  updateAppointment(appointmentGuid: string, appointment: Partial<EditAppointmentRequest>): Observable<boolean>;

  cancelAppointment(appointmentGuid: string): Observable<boolean>;

  loadAppointmentTypes(): Observable<AppointmentType[]>;

  getAvailableAgents(startTime: string, endTime: string): Observable<AvailableAgent[]>;

  validAppointmentDates(start: string, end: string): {
    valid: boolean,
    errorMessage?: string
  };

  getAvailabilitySlots(appointmentTypeId: number, appointmentDate: string, clientOffset: number, locationId: number): Observable<Timeslot[]>;

  getLocations(): Observable<StoreLocation[]>;

  reassignAppointmentAgent(appointmentGuid: string, agent: string): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class AppointmentService implements IAppointmentService {

  constructor(private appointmentBackendService: AppointmentBackendService,
              private logger: LoggingService,
              private authService: AuthService,
              private translate: TranslatePipe) {
  }



  static ParseSlotsForDate(appointmentDate: string, slots: Timeslot[], timezone: MicrosoftTimeZone): Timeslot[] {
    const requestedDate = moment(appointmentDate).startOf('day');
    return slots
      .map(slot => {
        const localStart = Timezones.convertTime(slot.start, timezone);
        const localEnd = Timezones.convertTime(slot.end, timezone);

        if (
          moment(localStart).isSameOrAfter(requestedDate, 'day') &&
          moment(localEnd).isSameOrBefore(requestedDate, 'day')
        ) {
          const localStartTime = Timezones.convertTime(localStart, timezone, 'HH:mm');
          const localEndTime = Timezones.convertTime(localEnd, timezone, 'HH:mm');
          return {
            start: slot.start,
            end: slot.end,
            label: `${localStartTime} - ${localEndTime}`
          };
        }
        return null;
      })
      .filter(slot => slot !== null);
  }


  filterAppointmentData(
    appointmentTypeId: number,
    callType: string,
    status: number,
    agents: string[],
    from: string,
    to: string,
    pagesize: number,
    pagenumber: number): Observable<AppointmentSearchResponseResult> {
    return new Observable(
      observer => {
        this.appointmentBackendService.filterAppointmentData(
          this.authService.currentAgent.value.authToken,
          appointmentTypeId,
          callType,
          status,
          agents,
          from,
          to,
          pagesize,
          pagenumber).subscribe(
          data => {
            observer.next(data?.result);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting appointment data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  reassignAppointmentAgent(appointmentGuid: string, agent: string): Observable<boolean> {
    const currentAgent = this.authService.currentAgent.getValue();
    return new Observable(
      observer => {
        this.appointmentBackendService.reassignAppointmentAgent(
          appointmentGuid,
          currentAgent,
          agent,
          ).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error reassigning appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  createAppointment(appointment: CreateAppointmentRequest): Observable<boolean> {
    const agent = this.authService.currentAgent.getValue();

    return new Observable(
      observer => {
        this.appointmentBackendService.createAppointment(agent, appointment).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error inserting appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }


  updateAppointment(appointmentGuid: string, appointment: Partial<EditAppointmentRequest>): Observable<boolean> {
    const agent = this.authService.currentAgent.getValue();
    return new Observable(
      observer => {
        this.appointmentBackendService.updateAppointment(appointmentGuid, agent, {
          ...appointment,
          modifiedBy: agent.username
        }).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error updating appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  updateAppointmentStatus(appointmentGuid: string, status: StatusEnum): Observable<boolean> {
    const agent = this.authService.currentAgent.getValue();
    return new Observable(
      observer => {
        this.appointmentBackendService.updateAppointmentStatus(appointmentGuid, agent, status).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error updating appointment status', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  cancelAppointment(appointmentGuid: string): Observable<boolean> {
    return this.appointmentBackendService.cancelAppointment(this.authService.currentAgent.getValue(), appointmentGuid)
      .pipe(
        map(() => true),
        catchError(err => {
          this.logger.error('Error deleting appointment', err);
          return of(false);
        })
      );
  }

  loadAppointmentTypes(): Observable<AppointmentType[]> {
    return new Observable<AppointmentType[]>(observer => {
      this.appointmentBackendService.loadAppointmentTypes(this.authService.currentAgent.getValue()).subscribe(
        types => {
          observer.next(types);
          observer.complete();
        },
        err => {
          this.logger.error('Error getting Appointment Types', err);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }


  getAvailableAgents(startTime: string, endTime: string): Observable<AvailableAgent[]> {
    const currentAgent = this.authService.currentAgent.getValue();
    return new Observable<AvailableAgent[]>(observer => {
      this.appointmentBackendService.getAvailableAgents(currentAgent, startTime, endTime).subscribe(
        agents => {
          observer.next(agents);
          observer.complete();
        },
        err => {
          this.logger.error('Error getting Available Agents List', err);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }


  validAppointmentDates(start: string, end: string): {
    valid: boolean,
    errorMessage?: string
  } {

    const now = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    console.warn('startDate', startDate, now);
    if (startDate < now) {
      return {
        valid: false,
        errorMessage: this.translate.transform('APPOINTMENT_VALIDATION_ONLYFUTUREDATES', 'Only Future Appointment Dates are allowed.')
      };
    }

    if (endDate < startDate) {
      return {
        valid: false,
        errorMessage: this.translate.transform('APPOINTMENT_VALIDATION_ENDDATEMUSTBEGREATER', 'End Date must be greater than Start Date.')
      };
    }

    return {
      valid: true
    };

  }

  createAvailabilityBlock(request: CreateAvailabilitySlotRequest): Observable<boolean> {
    const agent = this.authService.currentAgent.getValue();
    request.startDateTime = Timezones.convertTimeToUtc(request.startDateTime, agent.timezone as MicrosoftTimeZone);
    request.endDateTime = Timezones.convertTimeToUtc(request.endDateTime, agent.timezone as MicrosoftTimeZone);
    return new Observable(
      observer => {
        this.appointmentBackendService.createAvailabilityBlock(agent, request).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error creating appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  updateAvailabilityBlock(appointmentGuid: string, request: CreateAvailabilitySlotRequest): Observable<boolean> {
    const agent = this.authService.currentAgent.getValue();
    request.startDateTime = Timezones.convertTimeToUtc(request.startDateTime, agent.timezone as MicrosoftTimeZone);
    request.endDateTime = Timezones.convertTimeToUtc(request.endDateTime, agent.timezone as MicrosoftTimeZone);
    return new Observable(
      observer => {
        this.appointmentBackendService.updateAvailabilityBlock(agent, appointmentGuid, request).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error updating appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  deleteAvailabilityBlock(appointmentGuid: string): Observable<boolean> {
    return new Observable(
      observer => {
        this.appointmentBackendService.deleteAvailabilityBlock(this.authService.currentAgent.getValue(), appointmentGuid).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error updating appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  getAvailabilitySlots(appointmentTypeId: number, appointmentDate: string, clientOffset: number, locationId: number = -1): Observable<Timeslot[]> {
    return new Observable<Timeslot[]>(observer => {
      const agent = this.authService.currentAgent.getValue();
      this.appointmentBackendService.getAvailabilitySlots(agent, appointmentTypeId, appointmentDate, clientOffset, locationId).subscribe(
        slots => {
          observer.next(slots);
          observer.complete();
        },
        err => {
          this.logger.error('Error getting Available Slots', err);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }

  getLocations(): Observable<StoreLocation[]> {
    return new Observable<StoreLocation[]>(observer => {
      const agent = this.authService.currentAgent.getValue();
      this.appointmentBackendService.getLocations(agent).subscribe(
        locations => {
          observer.next(locations);
          observer.complete();
        },
        err => {
          this.logger.error('Error getting locations', err);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }


}
